.myModal {
	position: fixed;
	inset: 0;
	display: none;
	background: rgba($color: #000000, $alpha: .5);
}

.myModalContent {
	padding: 25px;
	background: #fff;
	border-radius: 16px;
	min-width: 250px;
}

.myModal.active {
	display: flex;
	justify-content: center;
	align-items: center;
}