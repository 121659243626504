.loader {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	border: 3px dashed teal;
	animation: rotate 2s infinite linear;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg) scale(1);
	}

	50% {
		transform: rotate(180deg) scale(1.2);
	}

	100% {
		transform: rotate(360deg) scale(1);
	}
}