* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#root {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
}

.App {
	max-width: 1200px;
	margin: 0 auto;
}

.post {
	display: flex;
	padding: 15px;
	border: 2px solid teal;
	margin-top: 15px;
	justify-content: space-between;
	align-items: center;
}

.post__btns {
	display: flex;
}

.post-enter {
	opacity: 0;
	transform: translateX(-350px);
}

.post-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: all .5s ease-in-out;
}

.post-exit {
	transform: translateX(0);
	opacity: 1;
}

.post-exit-active {
	opacity: 0;
	transform: translateX(350px);
	transition: all .5s ease-in-out;
}

.page__wrapper {
	margin-top: 30px;
	display: flex;
	justify-content: center;
}

.page {
	padding: 10px;
	border: 1px solid teal;
	cursor: pointer;
}

.page__current {
	border: 2px solid orange;
	font-weight: 700;
}

.navbar {
	width: 100%;
	padding: 15px;
	display: flex;
	align-items: center;
	background: lightgray;
}

.navbar__links {
	margin-left: auto;

	* {
		text-decoration: none;
		font-size: 15px;
		font-weight: 400;
		text-transform: uppercase;
	}

	& *:not(:last-child) {
		margin-right: 20px;
	}
}